import { createTheme } from "@material-ui/core";

const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "rgba(99,63,181,0.93)",
    },
    secondary: {
      main: "#9C66F3",
    },
    error: {
      main: "#d32f2f",
    },
    background: {
      default: "#05092B",
      paper: "#24344d",
    },
  },
  typography: {
    fontFamily: "Noto Sans,sans-serif",
  },
});

export default theme;
