import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  makeStyles,
  Card,
  Chip,
  Divider,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";

import { useLocation, Link } from "react-router-dom";

import TagsCard from "./TagsCard";

const useStyles = makeStyles((theme) => ({
  chipTag: {
    marginRight: "0.5rem",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

function Ctf() {
  const [posts, setPosts] = useState([]);
  const [tags, setTags] = useState({});

  const classes = useStyles();

  function formatDate(postDate) {
    var date = new Date(Date.parse(postDate));
    var formattedDate = date.toLocaleDateString("en-us", {
      month: "long",
      year: "numeric",
    });

    return formattedDate;
  }

  useEffect(() => {
    axios.get("/api/posts/?category=CTF").then((response) => {
      setPosts(response.data);
    });
  }, []);

  useEffect(() => {
    var count = {};
    posts.forEach((p) => {
      p.tags.forEach((t) => {
        count[t] = (count[t] || 0) + 1;
      });
    });

    setTags(count);
  }, [posts]);

  var query = new URLSearchParams(useLocation().search);
  var tagFilter = query.get("tag");
  return (
    <>
      <Grid
        container
        direction="column"
        style={{
          paddingLeft: "5vw",
          paddingRight: "5vw",
        }}
      >
        <Grid item xs={12} align="left">
          <Typography
            variant="h4"
            style={{ paddingTop: 20, paddingBottom: 20 }}
          >
            Capture the Flag Writeups
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container direction="row" spacing={5}>
            <Hidden smDown>
              <Grid item xs={3}>
                <TagsCard tags={tags} endpoint={"ctf"} />
              </Grid>
            </Hidden>

            <Grid item xs={12} md={9}>
              <Card>
                <List>
                  {posts
                    .filter((p) => {
                      if (tagFilter) {
                        return p.tags.includes(tagFilter);
                      } else {
                        return true;
                      }
                    })
                    .map((p) => {
                      return (
                        <>
                          <ListItem
                            button
                            component={Link}
                            to={`/ctf/post/${p.slug}`}
                          >
                            <ListItemText
                              primary={p.title}
                              secondary={p.tags.map((t) => {
                                return (
                                  <Chip
                                    className={classes.chipTag}
                                    clickable
                                    size="small"
                                    label={t}
                                    component={Link}
                                    to={`/ctf?tag=${t}`}
                                  />
                                );
                              })}
                            />
                            <Hidden mdDown>{formatDate(p.date)}</Hidden>
                          </ListItem>
                          <Divider />
                        </>
                      );
                    })}
                </List>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Ctf;
