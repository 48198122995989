import React from "react";

import { Button, Divider, Grid, Typography } from "@material-ui/core";

function Comment(props) {
  const { primary, secondary } = props;
  return (
    <>
      <div
        style={{
          marginTop: "5vh",
          marginBottom: "5vh",
        }}
      >
        <Typography
          variant="h4"
          align="left"
          gutterBottom
          style={{ fontSize: "2rem" }}
        >
          <em>{primary}</em>
        </Typography>
        <Typography
          variant="body"
          paragraph
          align="left"
          style={{ color: "#C5C5C5", fontSize: "1rem" }}
        >
          {secondary}
        </Typography>
      </div>
    </>
  );
}

function Testimonials() {
  return (
    <>
      <Grid
        container
        direction="column"
        style={{
          paddingLeft: "5vw",
          paddingRight: "5vw",
        }}
        alignItems="center"
      >
        <Grid item xs={12} align="left" style={{ width: "100%" }}>
          <Typography
            variant="h4"
            style={{ paddingTop: 20, paddingBottom: 20 }}
          >
            Testimonials
          </Typography>
        </Grid>

        <div style={{ width: "100%" }}>
          <Grid item xs={12} md={4} align="left">
            <Typography
              variant="body"
              style={{ paddingTop: 20, paddingBottom: 20, color: "#B3B3B3" }}
            >
              Read some reviews from student’s that I have tutored in the past.
              My Wyzant profile can be found here:
              <Button
                href="https://is.gd/rRJ6re"
                target="_blank"
                color="secondary"
                style={{
                  textDecoration: "underline",
                  textTransform: "none",
                  fontWeight: 1000,
                  paddingLeft: "0px",
                }}
              >
                <em>https://www.wyzant.com/match/tutor/88454803</em>
              </Button>
            </Typography>
          </Grid>
        </div>

        <Grid item xs={12} md={6} align="center">
          <Comment
            primary={"Great Experience!"}
            secondary={
              "“Antoni is very good at what he does. Not only is Antoni very knowledgeable in his areas of expertise, but he is very understanding, patient, and kind. Antoni is a great tutor and I highly suggest using his services.” "
            }
          />
          <Divider />
          <Comment
            primary={"Excellent!"}
            secondary={
              "“Antoni helped me learn more about web scraping. He patiently walked me through some code and went over big picture concepts that had been confusing me greatly. It was a very helpful session and gave me more ideas for how to tackle my project since I have a greater understanding of web scraping now.” "
            }
          />
          <Divider />
          <Comment
            primary={"Knowledgeable and a pleasure to work with."}
            secondary={
              '"This was the first of what I hope to be a regular session with Antoni as he helps me to develop my Python skills. So far we talk more about environment issues of loading an using numpy and matplotlib. We kind of wrote the hello world program for graphics"'
            }
          />
          <Divider />
          <Comment
            primary={"Very thorough, informative, and well prepared"}
            secondary={
              '"Antoni is an expert in Python, Java and likely several other coding languages. He does a great job simplifying and thoroughly explaining a difficult topic for me(coding Java and Python). So far he’s taught me far more than my professor has, I highly recommend him"'
            }
          />
          <Divider />

          <Typography
            variant="h6"
            align="left"
            style={{ marginTop: "5vh", marginBottom: "5vh" }}
          >
            See all reviews here:{" "}
            <Button
              href="https://is.gd/rRJ6re"
              target="_blank"
              color="secondary"
              style={{
                textDecoration: "underline",
                textTransform: "none",
                fontWeight: 1000,
              }}
            >
              https://www.wyzant.com/match/tutor/88454803
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default Testimonials;
