import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import axios from "axios";

process.env.NODE_ENV === "development"
  ? (axios.defaults.baseURL = "http://127.0.0.1:8000")
  : (axios.defaults.baseURL = "");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
