import React, { useState } from "react";

import { makeStyles, Button, Grid, Typography } from "@material-ui/core";

import { TreeView, TreeItem } from "@material-ui/lab";

import { Link } from "react-router-dom";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LaunchIcon from "@material-ui/icons/Launch";

import ResumeItemCard from "./ResumeItemCard";

const useStyles = makeStyles((theme) => ({
  accordion: {
    background: "rgba(0,0,0,0.0)",
  },
  list: {
    paddingLeft: "1vw",
    [theme.breakpoints.down("sm")]: {
      listStyleType: "none",
    },
  },
  listItem: {
    paddingBottom: "1vh",
  },
}));

function Resume() {
  const classes = useStyles();

  const [expandedItems, setExpandedItems] = useState({
    workExperience: true,
    projects: true,
    education: true,
    certifications: true,
  });

  const handleNodeSelect = (value) => {
    const expandedItemsCopy = JSON.parse(JSON.stringify(expandedItems));
    expandedItemsCopy[value] = !expandedItemsCopy[value];
    setExpandedItems(expandedItemsCopy);
  };

  const setExpansionOnAll = (expand) => {
    setExpandedItems({
      workExperience: expand,
      projects: expand,
      education: expand,
      certifications: expand,
    });
  };

  return (
    <Grid
      container
      direction="column"
      style={{
        paddingLeft: "5vw",
        paddingRight: "5vw",
        marginBottom: "5vh",
      }}
    >
      <Grid item xs={12} align="left">
        <Typography variant="h4" style={{ paddingTop: 20, paddingBottom: 20 }}>
          Resume
        </Typography>

        <Button color="secondary" onClick={() => setExpansionOnAll(true)}>
          Expand All
        </Button>

        <Button color="primary" onClick={() => setExpansionOnAll(false)}>
          Collapse All
        </Button>

        <Grid container direction="column">
          <TreeView
            expanded={Object.keys(expandedItems).filter(
              (section) => expandedItems[section] === true
            )}
            onNodeSelect={(event, value) => handleNodeSelect(value)}
          >
            <TreeItem
              collapseIcon={<ExpandMoreIcon />}
              expandIcon={<ChevronRightIcon />}
              nodeId="workExperience"
              label={<Typography variant="h6">WORK EXPERIENCE</Typography>}
            >
              {/* Computer Science tutor  */}
              <ResumeItemCard
                title="Computer Science Tutor"
                subtitle="Self-Employed"
                date="December 2020 - Present"
              >
                <Button
                  style={{
                    textTransform: "none",
                    padding: "0.2rem",
                    paddingTop: "0.1rem",
                    paddingBottom: "0.1rem",
                    marginBottom: "1vh",
                  }}
                  color="secondary"
                  variant="outlined"
                  href="https://is.gd/rRJ6re"
                  target="_blank"
                >
                  See my Profile on Wyzant{" "}
                  <LaunchIcon style={{ height: "1rem" }} />
                </Button>
                <ul className={classes.list}>
                  <li className={classes.listItem}>
                    Provided online tutoring, through Wyzant, to students,
                    professionals, and hobbyists on topics including Python and
                    C
                  </li>
                  <li className={classes.listItem}>
                    Prepared personalized lessons for each student
                  </li>
                  <li className={classes.listItem}>
                    Over 300 hours of tutoring
                  </li>
                </ul>
              </ResumeItemCard>

              {/* Software Engineer Marotta  */}
              <ResumeItemCard
                title="Software Engineer"
                subtitle="Marotta Controls Inc (Control Actuation Systems Department)"
                date="March 2020 - November 2020"
              >
                <ul className={classes.list}>
                  <li className={classes.listItem}>
                    {" "}
                    Developed, maintained, and tested software used for hardware
                    testing (C++ Visual Studio). This software ran test
                    sequences which required communication between power
                    supplies, thermal chamber and the CAS unit, and logged all
                    command and response messages
                  </li>
                  <li className={classes.listItem}>
                    {" "}
                    Maintained and tested CAS firmware. Proposed and implemented
                    fixes for firmware issues that were detected during testing
                  </li>
                  <li className={classes.listItem}>
                    {" "}
                    Responsible for writing software requirements which I then
                    used to write test procedures for CAS firmware
                  </li>
                  <li className={classes.listItem}>
                    {" "}
                    Performed QA witnessed validation testing for CAS firmware,
                    wrote and released software test reports
                  </li>
                </ul>
              </ResumeItemCard>
              {/* Software Engineer Marotta  */}

              <ResumeItemCard
                title="Electrical Engineer Intern"
                subtitle="Marotta Controls Inc (Electronics Department)"
                date="May 2019 - August 2019"
                lastInList
              >
                <ul className={classes.list}>
                  <li className={classes.listItem}>
                    Performed various analyses on DC/DC converter circuits found
                    in a Power Distribution Unit, including:
                    <ul style={{ listStyleType: "circle" }}>
                      <li className={classes.listItem}>
                        Frequency response analysis
                      </li>
                      <li className={classes.listItem}>
                        Dynamic response analysis
                      </li>
                      <li className={classes.listItem}>Voltage tolerance</li>
                      <li className={classes.listItem}>
                        Analysis of current limiting behavior
                      </li>
                    </ul>
                  </li>
                  <li className={classes.listItem}>
                    Automated the loading of settings to an oscilloscope, by
                    writing an Excel macro in Visual Basic, decreasing
                    Acceptance Test Procedure time from 2.5 days to 1.5 days
                  </li>
                  <li className={classes.listItem}>
                    Updated and released Engineering Change Notices for
                    Acceptance Test Procedures and Environmental Stress
                    Screening procedure to reflect changes made
                  </li>
                </ul>
              </ResumeItemCard>
            </TreeItem>

            <TreeItem
              style={{ marginTop: "2vh" }}
              collapseIcon={<ExpandMoreIcon />}
              expandIcon={<ChevronRightIcon />}
              nodeId="projects"
              label={<Typography variant="h6">PROJECTS</Typography>}
            >
              <Button
                component={Link}
                to={"/projects"}
                color="secondary"
                style={{ textDecoration: "underline" }}
              >
                See projects page
              </Button>
            </TreeItem>

            <TreeItem
              style={{ marginTop: "2vh" }}
              collapseIcon={<ExpandMoreIcon />}
              expandIcon={<ChevronRightIcon />}
              nodeId="education"
              label={<Typography variant="h6">EDUCATION</Typography>}
            >
              {/* Rutgers */}
              <ResumeItemCard
                title="Rutgers University, New Brunswick, NJ"
                subtitle="Bachelor of Science in Electrical and Computer Engineering"
                date="December 2019"
              >
                <i>GPA</i>: 3.89 / 4.00
                <br />
                <i>Honors</i>: Summa Cum Laude
              </ResumeItemCard>

              {/* Raritan Valley */}
              <ResumeItemCard
                title="Raritan Valley Community College, Branchburg, NJ"
                subtitle="Associate of Science in Engineering Science"
                date="December 2017"
                lastInList
              >
                <i>GPA</i>: 3.97 / 4.00
                <br />
                <i>Honors</i>: Dean's List for each semester attended
              </ResumeItemCard>
            </TreeItem>

            <TreeItem
              style={{ marginTop: "2vh" }}
              collapseIcon={<ExpandMoreIcon />}
              expandIcon={<ChevronRightIcon />}
              nodeId="certifications"
              label={<Typography variant="h6">CERTIFICATIONS</Typography>}
            >
              {/* eJPT */}
              <ResumeItemCard
                title="eLearnSecurity Junior Penetration Tester"
                date="January 2021"
                lastInList
              >
                <i>Certification ID</i>: 4603990
              </ResumeItemCard>
            </TreeItem>
          </TreeView>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Resume;
