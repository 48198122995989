import React from "react";

import {
  makeStyles,
  Grid,
  Typography,
  Card,
  Divider,
  Hidden,
} from "@material-ui/core";

import { TreeItem } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  card: {
    background: "rgba(0, 0, 0, 0.67)",
    border: "2px solid rgba(141, 141, 141, 0.3)",
    borderRadius: "4px",
    textAlign: "left",
    width: "100%",
    paddingLeft: "1vw",
    paddingRight: "1vw",
    marginTop: "2vh",
  },
  bodyText: {
    fontSize: "0.9rem",
    fontStyle: "light",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
  treeItem: {
    "&:hover": { cursor: "default" },
  },
}));

function ResumeItemCard(props) {
  const classes = useStyles();
  const { nodeId, title, subtitle, date, lastInList, children } = props;

  return (
    <>
      <TreeItem
        nodeId={nodeId}
        classes={{
          content: classes.treeItem,
        }}
        label={
          <Grid container direction="row" alignItems="center">
            <Hidden mdDown>
              <Grid item xs={12}>
                <div
                  style={{
                    width: "5vw",
                    height: lastInList ? "50%" : "100%",
                    borderLeft: "2px solid white",
                    position: "absolute",
                    top: "0",
                    bottom: "50%",
                  }}
                ></div>
              </Grid>
            </Hidden>

            <Hidden mdDown>
              <Grid item>
                <Divider
                  style={{
                    background: "white",
                    marginLeft: "0px",
                    height: "1px",
                    width: "30px",
                  }}
                />
              </Grid>
            </Hidden>

            <Grid
              item
              style={{
                flexGrow: 1,
                maxWidth: "95%",
              }}
            >
              <Card className={classes.card} fullWidth>
                <Typography variant="h5" align="left">
                  {title}
                </Typography>

                <Typography
                  style={{ color: "rgba(145, 145, 145, 1)" }}
                  variant="subtitle1"
                  align="left"
                >
                  {subtitle}
                </Typography>

                <Typography
                  style={{
                    color: "rgba(145, 145, 145, 1)",
                    fontStyle: "italic",
                    marginBottom: "3vh",
                  }}
                  variant="subtitle2"
                  align="left"
                >
                  {date}
                </Typography>

                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.bodyText}
                >
                  {children}
                </Typography>
              </Card>
            </Grid>
          </Grid>
        }
      />
    </>
  );
}

export default ResumeItemCard;
