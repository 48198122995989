import React from "react";

import { Link } from "react-router-dom";

import { makeStyles, Grid, Typography, Button } from "@material-ui/core";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${axios.defaults.baseURL}/static/images/glacier-np.jpg)`,
    backgroundSize: "cover",
    height: "100vh",
  },
  nameDiv: {
    border: "3px solid white",
    borderRadius: "10px",
    paddingLeft: "5vw",
    paddingRight: "5vw",
    paddingTop: "3vh",
    paddingBottom: "3vh",
    background: "rgba(20, 15, 15, 0.33)",
    width: "100%",
  },
  nameText: {
    letterSpacing: "0.02em",
    fontSize: "4rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  secondaryText: {
    fontSize: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    color: "#ADADAD",
  },
  buttonDiv: {
    width: "100%",
  },
  callToActionButton: {
    color: "white",
    border: "3px solid #5C00F1",
    boxSizing: "border-box",
    borderRadius: "10px",
  },
}));

function Home() {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignContent="center"
      className={classes.root}
    >
      <Grid
        item
        xs={10}
        md={6}
        style={{
          paddingTop: "25vh",
        }}
      >
        <div className={classes.nameDiv}>
          <Typography className={classes.nameText} variant="h2">
            Antoni Chrobot
          </Typography>
          <Typography className={classes.secondaryText} variant="h4" noWrap>
            Software Engineer &#8226; Tutor
          </Typography>
        </div>
      </Grid>

      <Grid
        item
        style={{
          width: "100%",
          position: "absolute",
          top: "70%",
        }}
      >
        <div className={classes.buttonDiv}>
          <Button
            className={classes.callToActionButton}
            variant="contained"
            color="primary"
            component={Link}
            to={"/about"}
          >
            SEE WHAT I OFFER
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

export default Home;
