import React from "react";

import { Link } from "react-router-dom";

import {
  makeStyles,
  Card,
  CardActionArea,
  Chip,
  Typography,
  CardContent,
} from "@material-ui/core";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  card: {
    background: "rgba(0, 0, 0, 0.67)",
    border: "2px solid #8D8D8D",
    borderRadius: "4px",
    opacity: "0.8",
    "&:hover": { opacity: "1" },
  },
  chipTag: {
    marginRight: "0.5vw",
    marginBottom: "1vh",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

function ProjectCard(props) {
  const classes = useStyles();
  const { title, img_thumbnail, date, slug, tags, description } = props;

  function formatDate(postDate) {
    var date = new Date(Date.parse(postDate));
    var formattedDate = date.toLocaleDateString("en-us", {
      month: "long",
      year: "numeric",
    });

    return formattedDate;
  }

  return (
    <Card className={classes.card}>
      <CardActionArea component={Link} to={`/projects/post/${slug}`}>
        <div
          style={{
            backgroundImage: `url(${axios.defaults.baseURL}${img_thumbnail})`,
            backgroundColor: "rgba(138, 138, 138, 0.2)",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
            height: "20vh",
          }}
        />
        <CardContent align="left">
          <Typography
            variant="h5"
            gutterBottom
            style={{
              fontSize: "1.5rem",
            }}
          >
            {title}
          </Typography>
          {tags.map((t) => {
            return (
              <Chip
                size="small"
                clickable
                className={classes.chipTag}
                label={t}
                component={Link}
                to={`projects?tag=${t}`}
              />
            );
          })}
          <p>{formatDate(date)}</p>
          <p>{description}</p>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default ProjectCard;
