import React from "react";

import {
  makeStyles,
  Card,
  CardContent,
  Chip,
  Divider,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";

import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "rgba(122, 122, 122, 0.8)",
    borderRadius: "5px",
  },
  chip: {
    background: "#C4C4C4",
    color: "#232323",
    marginLeft: "1vw",
  },
}));

function TagsCard(props) {
  const classes = useStyles();

  const { tags, endpoint } = props;

  return (
    <Card align="left" className={classes.root}>
      <CardContent>
        <List>
          <ListItem button component={Link} to={`/${endpoint}`}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography>All</Typography>
            </div>
          </ListItem>
          <Divider />
          {tags &&
            Object.keys(tags).map((t) => {
              return (
                <>
                  <ListItem
                    button
                    component={Link}
                    to={`/${endpoint}?tag=${t}`}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>{t}</Typography>
                      <Chip
                        size="small"
                        className={classes.chip}
                        label={tags[t]}
                      />
                    </div>
                  </ListItem>
                  <Divider />
                </>
              );
            })}
        </List>
      </CardContent>
    </Card>
  );
}

export default TagsCard;
