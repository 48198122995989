import React, { useState } from "react";
import axios from "axios";

import {
  makeStyles,
  Button,
  ClickAwayListener,
  Grid,
  SvgIcon,
  Typography,
  Toolbar,
  Tooltip,
} from "@material-ui/core";

import { mdiLinkedin, mdiGithub, mdiTwitter, mdiEmail } from "@mdi/js";

import ContactForm from "./ContactForm";

const useStyles = makeStyles((theme) => ({
  leftPanel: {
    paddingLeft: "5vw",
    paddingRight: "5vw",
    paddingBottom: "5vh",
  },
  rightPanel: {
    paddingLeft: "5vw",
    paddingRight: "5vw",
    background: `url(${axios.defaults.baseURL}/static/images/contactPageBackground.svg)`,
    backgroundColor: "rgba(0,0,0,0.7)",
    height: "100vh",
  },
  icon: {
    height: "7vh",
    width: "auto",
  },
  socialLink: {
    textDecoration: "none",
    color: "white",
    "&:hover": { color: theme.palette.primary.main },
  },
}));

const socialMediaEndpoints = [
  {
    icon: mdiEmail,
    fullUrl: "mailto:antoni.chrobot@gmail.com",
    endpoint: "antoni.chrobot@gmail.com",
    isEmail: true,
  },
  {
    icon: mdiLinkedin,
    fullUrl: "https://www.linkedin.com/in/antonichrobot/",
    endpoint: "/antonichrobot",
    isEmail: false,
  },
  {
    icon: mdiGithub,
    fullUrl: "https://github.com/achrobot1",
    endpoint: "/achrobot1",
    isEmail: false,
  },
  {
    icon: mdiTwitter,
    fullUrl: "https://twitter.com/AntoniChrobot",
    endpoint: "/antonichrobot",
    isEmail: false,
  },
];

function SocialMediaLink(props) {
  const classes = useStyles();

  const { icon, fullUrl, endpoint, isEmail } = props;

  const [toolTipOpen, setToolTipOpen] = useState(false);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      style={{ marginBottom: "3vh" }}
    >
      <Grid item>
        <a
          className={classes.socialLink}
          href={fullUrl}
          target="_blank"
          rel="noreferrer"
        >
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <SvgIcon className={classes.icon}>
                <path d={icon} />
              </SvgIcon>
            </Grid>
            <Grid item>
              <Typography variant="h5">{endpoint}</Typography>
            </Grid>
          </Grid>
        </a>
      </Grid>

      {isEmail && (
        <ClickAwayListener
          onClickAway={() => {
            setToolTipOpen(false);
          }}
        >
          <Grid item>
            <Tooltip
              open={toolTipOpen}
              title={`${endpoint} copied to clipboard`}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <Button
                color="secondary"
                onClick={() => {
                  setToolTipOpen(true);
                  navigator.clipboard.writeText(endpoint);
                }}
              >
                Copy
              </Button>
            </Tooltip>
          </Grid>
        </ClickAwayListener>
      )}
    </Grid>
  );
}

function Contact() {
  const classes = useStyles();

  return (
    <Grid container direction="row">
      <Grid item className={classes.leftPanel} xs={12} md={6}>
        <Grid container direction="column" align="left">
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              Contact Me
            </Typography>

            <Grid item xs={12}>
              <ContactForm />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item className={classes.rightPanel} xs={12} md={6}>
        <Grid container direction="column" align="left">
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              Connect With Me
            </Typography>

            <Toolbar />

            {socialMediaEndpoints.map((item) => {
              return (
                <SocialMediaLink
                  icon={item.icon}
                  fullUrl={item.fullUrl}
                  endpoint={item.endpoint}
                  isEmail={item.isEmail}
                />
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Contact;
