import React, { useState } from "react";

import { CSSTransition, TransitionGroup } from "react-transition-group";

import { Switch, Route, Link, useHistory, useLocation } from "react-router-dom";

import {
  makeStyles,
  AppBar,
  Button,
  Drawer,
  IconButton,
  Hidden,
  Popper,
  Paper,
  Tabs,
  Tab,
  Toolbar,
  Typography,
  MenuItem,
  MenuList,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import Post from "./Post";
import Home from "./Home";
import About from "./About";
import Projects from "./Projects";
import Contact from "./Contact";
import Resume from "./Resume";
import Testimonials from "./Testimonials";
import Ctf from "./Ctf";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: "rgba(23, 23, 23, 0.95)",
  },
  tab: {
    minWidth: "5vw",
  },
  contactButton: {
    background: "rgba(92, 0, 241, 0.3)",
    border: "2px solid #5C00F1",
    borderRadius: "10px",
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

function TabPanel(props) {
  const { orientation, closeDrawer } = props;

  const location = useLocation();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPage, setCurrentPage] = useState(
    location.pathname.split("/")[1]
  );

  const tabValues = ["/about", "/projects", "/resume", "/contact"];

  const currentTabValue = tabValues.includes(currentPage) && currentPage;

  const isMobile = () => {
    return "ontouchstart" in document.documentElement;
  };

  const handleTabChange = (event, newValue) => {
    if (newValue !== "more") {
      setCurrentPage(newValue);
      history.push(newValue);
      closeDrawer();
    } else {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  return (
    <div
      onMouseLeave={() => {
        setAnchorEl(null);
      }}
    >
      <Tabs
        TabIndicatorProps={{
          style: { display: "none" },
        }}
        value={currentTabValue}
        onChange={handleTabChange}
        orientation={orientation}
      >
        <Tab style={{ minWidth: "5vw" }} value="/about" label="About" />
        <Tab style={{ minWidth: "5vw" }} value="/projects" label="Projects" />
        <Tab style={{ minWidth: "5vw" }} value="/resume" label="Resume" />
        <Tab
          style={{ minWidth: "5vw" }}
          value="more"
          label={
            <div display="flex" style={{ alignItems: "center" }}>
              More
              <ArrowDropDownIcon style={{ verticalAlign: "middle" }} />
            </div>
          }
          onMouseEnter={(event) => {
            if (!isMobile()) {
              handleTabChange(event, "more");
            }
          }}
        />
        <Tab
          style={{ minWidth: "5vw" }}
          value="/contact"
          label={
            <Button
              style={{
                background: "rgba(117, 214, 20, 0.64)",
                border: "2px solid #05092B",
                borderRadius: "10px",
                paddingLeft: 10,
                paddingRight: 10,
              }}
              value="contact"
              variant="contained"
              color="primary"
              onClick={() => {
                handleTabChange(null, "/contact");
              }}
            >
              Contact
            </Button>
          }
        />
      </Tabs>
      <Popper
        open={anchorEl !== null}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        onClick={() => setAnchorEl(null)}
        style={{ zIndex: 10000 }}
      >
        <Paper>
          <MenuList>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                closeDrawer();
              }}
              component={Link}
              to={"/testimonials"}
            >
              Testimonials
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                closeDrawer();
              }}
              component={Link}
              to={"/ctf"}
            >
              CTF Writeups
            </MenuItem>
          </MenuList>
        </Paper>
      </Popper>
    </div>
  );
}

function Main() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const classes = useStyles();

  return (
    <>
      <AppBar className={classes.appBar} position="static">
        <Toolbar>
          <Link
            style={{
              textDecoration: "none",
              color: "white",
              display: "flex",
              flexGrow: 1,
            }}
            to="/"
          >
            <Typography style={{ display: "flex", flexGrow: 1 }} variant="h6">
              Antoni Chrobot
            </Typography>
          </Link>

          <Hidden mdUp>
            <IconButton onClick={() => setDrawerOpen(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
            >
              <TabPanel
                orientation="vertical"
                closeDrawer={() => setDrawerOpen(false)}
              />
            </Drawer>
          </Hidden>

          <Hidden smDown>
            <TabPanel
              orientation="horizontal"
              closeDrawer={() => setDrawerOpen(false)}
            />
          </Hidden>
        </Toolbar>
      </AppBar>

      <Switch>
        <TransitionGroup>
          <Route exact path="/">
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="fade"
            >
              <Home />
            </CSSTransition>
          </Route>

          <Route exact path="/home">
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="fade"
            >
              <Home />
            </CSSTransition>
          </Route>

          <Route exact path="/about">
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="fade"
            >
              <About />
            </CSSTransition>
          </Route>
          <Route exact path="/projects">
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="fade"
            >
              <Projects />
            </CSSTransition>
          </Route>
          <Route path="/resume">
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="fade"
            >
              <Resume />
            </CSSTransition>
          </Route>
          <Route path="/testimonials">
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="fade"
            >
              <Testimonials />
            </CSSTransition>
          </Route>
          <Route exact path="/ctf">
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="fade"
            >
              <Ctf />
            </CSSTransition>
          </Route>
          <Route path="/contact">
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="fade"
            >
              <Contact />
            </CSSTransition>
          </Route>
          <Route path="/ctf/post/:slug">
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="fade"
            >
              <Post />
            </CSSTransition>
          </Route>

          <Route path="/projects/post/:slug">
            <CSSTransition
              in={true}
              appear={true}
              timeout={1000}
              classNames="fade"
            >
              <Post />
            </CSSTransition>
          </Route>
        </TransitionGroup>
      </Switch>
    </>
  );
}

export default Main;
