import React, { useState, useEffect } from "react";

import hljs from "highlight.js";

import axios from "axios";

import { useParams } from "react-router-dom";

import { makeStyles, Card, Grid, Typography } from "@material-ui/core";

import "./style.css";

const useStyles = makeStyles((theme) => ({
  postText: {
    fontSize: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
}));

function Post(props) {
  const classes = useStyles();
  const { slug } = useParams();

  const [post, setPost] = useState({});
  useEffect(() => {
    axios
      .get(`/api/posts/?slug=${slug}`)
      .then((res) => {
        const resPost = res.data[0];

        // Set appropriate base url if running on development server
        if (process.env.NODE_ENV === "development") {
          resPost.body = resPost.body.replaceAll(
            "/static/",
            "http://127.0.0.1:8000/static/"
          );
        }

        setPost(resPost);
        hljs.highlightAll();

        window.MathJax.typeset();
      })
      .catch((err) => console.error(err));
  }, [slug]);

  function formatDate(postDate) {
    var date = new Date(Date.parse(postDate));
    var formattedDate = date.toLocaleDateString("en-us", {
      month: "long",
      year: "numeric",
    });

    return formattedDate;
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ paddingLeft: "2vw", paddingRight: "2vw" }}
    >
      <Grid item xs={12} sm={8} style={{ marginTop: "4vh" }}>
        {post && (
          <>
            <Typography variant="h4" align="left">
              {post.title}
            </Typography>
            <Typography
              variant="subtitle1"
              align="left"
              style={{ color: "rgba(145, 145, 145, 1)" }}
            >
              {formatDate(post.date)}
            </Typography>
          </>
        )}
        {post ? (
          <Card style={{ padding: "1vw", marginTop: "2vh" }}>
            <Typography
              component="p"
              variant="p"
              paragraph
              align="left"
              dangerouslySetInnerHTML={{ __html: post.body }}
              className={classes.postText}
            ></Typography>
          </Card>
        ) : (
          <h1>Post not found</h1>
        )}
      </Grid>
    </Grid>
  );
}

export default Post;
