import React, { useState, useEffect } from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";

import { useLocation } from "react-router-dom";

import ProjectCard from "./ProjectCard";
import TagsCard from "./TagsCard";
import axios from "axios";

function Projects() {
  const [posts, setPosts] = useState([]);

  const [tags, setTags] = useState({});

  useEffect(() => {
    axios.get("/api/posts/?category=Project").then((response) => {
      setPosts(response.data);
    });
  }, []);

  useEffect(() => {
    var count = {};
    posts.forEach((p) => {
      p.tags.forEach((t) => {
        count[t] = (count[t] || 0) + 1;
      });
    });

    setTags(count);
  }, [posts]);

  var query = new URLSearchParams(useLocation().search);
  var tagFilter = query.get("tag");

  return (
    <Grid
      container
      direction="column"
      style={{
        paddingLeft: "5vw",
        paddingRight: "5vw",
      }}
    >
      <Grid item xs={12} align="left">
        <Typography variant="h4" style={{ paddingTop: 20, paddingBottom: 20 }}>
          Projects
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container direction="row" spacing={5}>
          <Hidden smDown>
            <Grid item xs={3}>
              <TagsCard tags={tags} endpoint={"projects"} />
            </Grid>
          </Hidden>

          <Grid item xs={12} md={9}>
            <Grid container spacing={8}>
              {posts
                .filter((p) => {
                  if (tagFilter) {
                    return p.tags.includes(tagFilter);
                  } else {
                    return true;
                  }
                })
                .map((p) => {
                  return (
                    <Grid item xs={12} sm={6} md={4}>
                      <ProjectCard {...p} />
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Projects;
