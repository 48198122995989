import React, { useState } from "react";

import axios from "axios";

import {
  makeStyles,
  Button,
  Grid,
  Snackbar,
  Typography,
  TextField,
} from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: "3vh",
    background: "rgba(196, 196, 196, 0.7)",
  },
  input: {
    color: "black",
    fontSize: "1.2rem",
  },
  submitButton: {
    color: "#71C95B",
    borderColor: "#71C95B",
    fontSize: "1.5rem",
    borderWidth: "0.1rem",
  },
}));

function ContactForm() {
  const classes = useStyles();

  const [formValues, setFormValues] = useState({
    name: "",
    email_address: "",
    message: "",
  });

  const [successMessageOpen, setSuccessMessageOpen] = useState(false);

  const set = (name) => {
    return ({ target: { value } }) => {
      setFormValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
  };

  const getCookie = (name) => {
    var cookie;
    if (document.cookie.includes(`${name}=`)) {
      cookie = document.cookie.split(`${name}=`)[1].split(";")[0];
    }
    return cookie;
  };

  const handleSubmit = async (event) => {
    const postData = {
      ...formValues,
    };

    axios({
      method: "post",
      url: "/contact-me/",
      data: postData,
      headers: {
        "X-CSRFToken": getCookie("csrftoken"),
      },
    })
      .then((response) => {
        if (response.data.includes("Message success")) {
          setFormValues({ name: "", email_address: "", message: "" });
          setSuccessMessageOpen(true);
        }
      })
      .catch((response) => {
        console.log(response);
      });
    event.preventDefault();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography variant="subtitle1" gutterBottom>
          Name:
        </Typography>
        <TextField
          id="name"
          name="name"
          onChange={set("name")}
          value={formValues.name}
          className={classes.textField}
          inputProps={{ className: classes.input }}
          variant="filled"
          required
          fullWidth
        />

        <Typography variant="subtitle1" gutterBottom>
          Email:
        </Typography>
        <TextField
          id="email_address"
          type="email"
          name="email_address"
          onChange={set("email_address")}
          value={formValues.email_address}
          className={classes.textField}
          inputProps={{ className: classes.input }}
          variant="filled"
          required
          fullWidth
        />

        <Typography variant="subtitle1" gutterBottom>
          Message:
        </Typography>
        <TextField
          id="message"
          name="message"
          onChange={set("message")}
          value={formValues.message}
          className={classes.textField}
          inputProps={{ className: classes.input }}
          variant="filled"
          required
          fullWidth
          multiline
          rows={4}
        />

        <input
          type="hidden"
          name="csrfmiddlewaretoken"
          value={getCookie("csrftoken")}
        />
        <Grid item align="center">
          <Button
            type="submit"
            className={classes.submitButton}
            variant="outlined"
            size="large"
          >
            Submit
          </Button>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={successMessageOpen}
          autoHideDuration={3000}
          message="Message sent!"
        >
          <MuiAlert elevation={6} variant="filled" severity="success">
            Message sent!
          </MuiAlert>
        </Snackbar>
      </form>
    </>
  );
}

export default ContactForm;
