import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Main from "./components/Main";

import theme from "./theme";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="App">
          <Main />
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default App;
