import { makeStyles, Grid, Typography, Hidden } from "@material-ui/core";
import React from "react";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(360deg, rgba(0, 0, 0, 0.276) 25.64%, rgba(0, 0, 0, 0) 53.45%), linear-gradient(180deg, rgba(0, 0, 0, 0.102) 15.63%, rgba(0, 0, 0, 0) 28.92%), linear-gradient(270deg, rgba(0, 0, 0, 0.084) 71.56%, rgba(0, 0, 0, 0) 78.53%), linear-gradient(90deg, rgba(0, 0, 0, 0.108) 12.14%, rgba(0, 0, 0, 0) 19.63%), url(${axios.defaults.baseURL}/static/images/austria-bridge.png)`,
    backgroundSize: "cover",
    maxWidth: "100vw",
    height: "100vh",
  },
  imageOfSelf: {
    background: `url(${axios.defaults.baseURL}/static/images/self.jpg)`,
    backgroundSize: "cover",
    borderRadius: "50%",
    border: "1px solid gray",
    width: "60vw",
    height: "60vw",
    maxWidth: "300px",
    maxHeight: "300px",
  },
  whoamiPanel: {
    background: "rgba(8, 8, 8, 0.8)",
    height: "100%",
    paddingLeft: "8vw",
    paddingRight: "8vw",
    paddingTop: "5vh",
  },
}));

function About() {
  const classes = useStyles();
  return (
    <Grid align="right" className={classes.root}>
      <Grid item xs={12} md={8} align="left" className={classes.whoamiPanel}>
        <Hidden mdUp>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className={classes.imageOfSelf}></div>
          </div>
        </Hidden>

        <Typography
          style={{
            fontFamily: "Ubuntu,monospace",
            color: "rgba(72, 89, 243, 1)",
          }}
          variant="h3"
          gutterBottom
        >
          $ whoami
        </Typography>
        <Typography paragraph variant="body1">
          I am a Software Engineer, Cybersecurity enthusiast, and experienced
          tutor. I am always working on projects and learning new things, so I
          made this site to share some of my posts and writeups that document my
          experiences.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default About;
